import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const DewateringPage = () => {
  return (
    <Layout pageTitle="Dewatering">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/dewatering-hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Dewatering</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <p>
                There are several ways to convert liquids to solids when dewatering sludge but none of them are as effective as our revolutionary, rotary drum technology. These mobile, self-contained units can dewater a wide variety of sludge at a rate of up to 52 m3/hr in even the remotest of locations.  In addition, the energy, water, and polymer required by this unique process are low.
              </p>
              <p>
                The result is superior to any other dewatering methods currently used with superior effluent quality of less than 100 mg/L and cake solids between 20 and 65 per cent.
              </p>
              <p>
                We also use geo-textile bags for dewatering. They are made of a permeable fabric. Slurry is forced into dewatering bags at an high rate. The small pores in the geo-textile material separates water from solids in a slower but highly effective process that results in lower energy inputs, reduced costs, and a dewatered product between 15 and 45 per cent solids
              </p>
            </Col>
            <Col>
              <video autoPlay loop muted playsInline id="dewatering-video" preload="metadata">
                <source src="https://wordpress.wessuc.com/wp-content/uploads/2021/10/Dewatering-Video-1-1.mp4" type="video/mp4" />
              </video>
              <p className="media-description">
                The video above demonstrates the detwatering process using an In
                the Round (ITR). You can see how clean the water removed is, and
                this can be recycled for washing machinery, equipment and more.
                All of this can be done right on site. Wessuc can also haul away
                your solid material from the dewatering process.
              </p>
              <Link to="/contact">
                <Button variant="primary" className="shadow">
                  CONTACT US TODAY FOR YOUR DEWATERING NEEDS
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>BEST PRACTICES</h3>
              <p>
                Depending on what industry you’re in the term “dewatering” can mean different things. Construction site dewatering typically refers to the management of water on a site, whether it be pond water or ground water to allow construction activities to continue uninhibited. The number of dewatering methods are as varied as the number of liquid waste streams they treat.
              </p>
              <p>
                Wessuc has experience completing projects with each of these best practices and has been working to improve the dewatering process in Canada for over 10 years to achieve efficiency and reduce cost and environmental impact.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/dewatering_best_practices.jpg"
                alt="a Wessuc ITR dumping a load of dark brown material" />
              <p>
              <Link to="/articles/dewatering-best-practices-to-optimize-efficiency-cost-and-environmental-impact">
                <Button variant="primary" className="shadow">BEST PRACTICES FOR DEWATERING</Button>
              </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default DewateringPage